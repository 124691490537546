import React, { useContext, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { makeStyles, useTheme, useMediaQuery } from "@material-ui/core";
import { withRouter } from "react-router";
import axios from "axios";

import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import Divider from "@material-ui/core/Divider";
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';

import { CreateFlightHours, SetFetchedDataStatus, SetShowMoreResults } from "../../../Actions/FlightSearch";

import CountAgeStr from "../Functions/CountAgeStr";
import GetMinimalPrices from "../Functions/GetMinimalPrices";

import Transition from "../../Common/Transition";
import FlightGroups from "../FlightGroups/FlightGroups";
import FiltersBar from "./FilterSortComponents/FiltersBar";
import FlightMatrix from "./FlightMatrix";
import FlightSearchSkeleton from "./FlightSearchSkeleton";
import FlightList from "./FlightList";

import moment from "moment";
import { CartConstructionReplaceProductContext } from "../../CartMaterial/utils/cartConstructionReplaceProductContext";
import CartFlightPriceDetail from "../../CartMaterial/CartFlightPriceDetail";
import UpdateCart from "../../Itinerary/Functions/UpdateCart";
import { useAdaltePackages } from "../../Common/hooks/adaltePackages";

const main_color = JSON.parse(localStorage.getItem("config")).main_color;

const useStyles = makeStyles((theme) => ({
    genericText: {
        fontFamily: "Roboto",
        fontStyle: "normal",
        color: '#0000008A',
        letterSpacing: 1.25
    },
    fontWeight500: {
        fontWeight: 500
    },
    fontWeight700: {
        fontWeight: 700
    },
    bold: {
        fontWeight: 'bold'
    },
    fontSize10: {
        fontSize: 10
    },
    fontSize12: {
        fontSize: 12
    },
    fontSize14: {
        fontSize: 14
    },
    fontSize16: {
        fontSize: 16
    },
    uppercase: {
        textTransform: "uppercase"
    },
    orange: {
        color: `${main_color}`
    },
    textCenter: {
        textAlign: "center"
    },
    textRight: {
        textAlign: "right"
    },
    genericIcon: {
        marginTop: 4,
        color: '#0000008A'
    },
    spacer: {
        padding: "12px 0px"
    },
    formShadow: {
        borderRadius: 15,
        boxShadow: '0 1px 6px rgba(32,33,36,0.28)',
        margin: "16px 4px"
    },
    skeletonPadding: {
        padding: "16px"
    },
    editButton: {
        border: `2px solid ${main_color}`,
        background: "#FFFFFF",
        boxSizing: "border-box",
        borderRadius: 4,
        padding: "6px 16px"
    },
    borderTableCell: {
        borderBottom: "none"
    },
    moreResultsButton: {
        border: `2px solid ${main_color}`,
        color: `${main_color}`,
        background: "#FFFFFF"
    },
    genericBorder: {
        background: "#FFFFFF",
        border: "1px solid #DDDDDD",
        boxSizing: "border-box"
    }
}));

const FlightSearch = withRouter(({ router, params }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const theme = useTheme();
    const isHandHeld = useMediaQuery(theme.breakpoints.down(960));
    const isTablet = useMediaQuery(theme.breakpoints.between(600, 960));
    const isMobile = useMediaQuery(theme.breakpoints.down(600));

    const language = useSelector(store => store.header.language);
    const end_date = useSelector(store => store.trip.end_date);
    const trip = useSelector(store => store.trip.data_trip);
    const allData = useSelector(store => store.trip.all_data);
    const trip_id = useSelector(store => store.trip.trip_id);
    const trip_currency = useSelector(store => store.trip.currency);
    const flight_groups = useSelector(store => store.flight.flight_groups);
    const index_group = useSelector(store => store.flight.index_group);
    const flight_list = useSelector(store => store.flight_search.flight_list);
    const flight_list_render = useSelector(store => store.flight_search.flight_list_render);
    const flight_providers = useSelector(store => store.flight.provider_list);
    const fetched_status = useSelector(store => store.flight_search.fetched_status);
    const matrix_status = useSelector(store => store.flight_search.matrix_status);
    const search_providers = useSelector(store => store.flight_search.providers);
    const show_more_results = useSelector(store => store.flight_search.show_more_results);
    const providers_count = useSelector(store => store.flight_search.providers_count);
    const slider_time = useSelector(store => store.flight_search.slider_time);
    const min_duration = useSelector(store => store.flight_search.min_duration);
    const max_duration = useSelector(store => store.flight_search.max_duration);
    const airports = useSelector(store => store.flight_search.airports);
    const providers_list = useSelector(store => store.trip.providers);
    const flight_params = useSelector(store => store.itinerary_type.flight_circuit);
    const user = useSelector(store => store.user.user);
    const from_duplication = useSelector(store => store.menu.from_duplication);

    const accommodation_cart = useSelector(store => store.accommodation.sort_cart);
    const flight_cart = useSelector(store => store.flight.cart);
    const car_cart = useSelector(store => store.cars_search.cart);
    const manual_item_list = useSelector(store => store.cart.manual_item_list);
    const traveler_groups = useSelector(store => store.trip.traveler_groups);

    const [providersObj, setProvidersObj] = useState({});
    const [searched_group, setSearchGroup] = useState(null);
    const [editSearch, setEditSearch] = useState(false);
    const [loadingRequest, setLoadingRequest] = useState(false);
    const [countProviders, setCountProviders] = useState(0);
    const replaceProductContext = useContext(CartConstructionReplaceProductContext);
    const adaltePackages = useAdaltePackages();

    useEffect(() => {
        let source = axios.CancelToken.source();
        if (from_duplication) {
            updateCartFromDuplication()
        }
        if (flight_groups[index_group].flexible_date && matrix_status === null) {
            GetMinimalPrices(flight_groups, flight_providers, index_group, trip_id, trip_currency, end_date, user, params.use_cache, "matrix", dispatch, source);
        } else if (fetched_status === null) {
            GetMinimalPrices(flight_groups, flight_providers, index_group, trip_id, trip_currency, end_date, user, params.use_cache, "set_flights", dispatch, source);
        }
        return () => {
            source.cancel();
        };
    }, []);

    useEffect(() => {
        setSearchGroup(flight_groups[index_group]);
        checkProviders();
    }, [router]);

    useEffect(() => {
        if (searched_group !== null && countProviders === providers_count) {
            if (flight_params.length !== 0) {
                let filter_list = [...flight_list];
                let tmp_slider_time = [...slider_time];
                let tmp_min_duration = min_duration;
                let tmp_max_duration = max_duration;
                let param = flight_params[0];
                let has_manual_flight = filter_list.find((flight) => providersObj[flight.provider_id].code === 'custom_flight');
                if (param.display_manual_response_first && has_manual_flight !== undefined) {
                    filter_list = filter_list.sort((a, b) => {
                        if (a.provider_id === has_manual_flight.provider_id && b.provider_id !== has_manual_flight.provider_id) {
                            return -1;
                        }
                        if (a.provider_id === has_manual_flight.provider_id && b.provider_id === has_manual_flight.provider_id) {
                            return parseFloat(a.total_price) - parseFloat(b.total_price);
                        }
                        return 1;
                    });
                }
                if (param.hide_gds_response_on_manual_response) {
                    if (has_manual_flight !== undefined) {
                        filter_list = filter_list.filter((flight) => flight.provider_id === has_manual_flight.provider_id);
                    }
                }
                if (param.disallowed_arrival_airport !== null && param.disallowed_arrival_airport.length !== 0) {
                    let disallowed_arrival_airport_key = param.disallowed_arrival_airport.map(airport => {
                        return airport.id;
                    });
                    filter_list = filter_list.filter((flight) => {
                        for (let i = 0; i < flight.outbounds.length; i++) {
                            for (let j = 0; j < flight.outbounds[i].legs.length; j++) {
                                if (flight.outbounds[i].legs[j].origin_airport !== undefined || flight.outbounds[i].legs[j].destination_airport !== undefined) {
                                    if (disallowed_arrival_airport_key.includes(flight.outbounds[i].legs[j].origin_airport) || disallowed_arrival_airport_key.includes(flight.outbounds[i].legs[j].destination_airport)) {
                                        return false;
                                    }
                                }
                            }
                        }
                        return true;
                    });
                }
                if (param.disallowed_departure_airport !== null && param.disallowed_departure_airport.length !== 0) {
                    let disallowed_departure_airport_key = param.disallowed_departure_airport.map(airport => {
                        return airport.id;
                    });
                    filter_list = filter_list.filter((flight) => {
                        for (let i = 0; i < flight.outbounds.length; i++) {
                            for (let j = 0; j < flight.outbounds[i].legs.length; j++) {
                                if (flight.outbounds[i].legs[j].origin_airport !== undefined || flight.outbounds[i].legs[j].destination_airport !== undefined) {
                                    if (disallowed_departure_airport_key.includes(flight.outbounds[i].legs[j].origin_airport) || disallowed_departure_airport_key.includes(flight.outbounds[i].legs[j].destination_airport)) {
                                        return false;
                                    }
                                }
                            }
                        }
                        return true;
                    });
                }
                if (param.start_departure_time !== null && param.end_departure_time !== null) {
                    tmp_slider_time[0].departure[0] = param.start_departure_time;
                    tmp_slider_time[0].departure[1] = param.end_departure_time;
                }
                if (param.start_arrival_time !== null && param.end_arrival_time !== null) {
                    tmp_slider_time[0].arrival[0] = param.start_arrival_time;
                    tmp_slider_time[0].arrival[1] = param.end_arrival_time;
                }
                if (param.start_departure_time_inbound !== null && param.end_departure_time_inbound !== null) {
                    tmp_slider_time[1].departure[0] = param.start_departure_time_inbound;
                    tmp_slider_time[1].departure[1] = param.end_departure_time_inbound;
                }
                if (param.start_arrival_time_inbound !== null && param.end_arrival_time_inbound !== null) {
                    tmp_slider_time[1].arrival[0] = param.start_arrival_time_inbound;
                    tmp_slider_time[1].arrival[1] = param.end_arrival_time_inbound;
                }
                if (param.flight_duration_min !== null) {
                    tmp_min_duration = param.flight_duration_min / 60;
                }
                if (param.flight_duration_max !== null) {
                    tmp_max_duration = param.flight_duration_max / 60;
                }
                if (param.change_airport) {
                    dispatch({ type: 'FLIGHT_TOGGLE_CHANGE_AIRPORT' });
                }
                dispatch({ type: 'FLIGHT_SET_SLIDER_TIME', payload: tmp_slider_time });
                dispatch({ type: 'FLIGHT_SET_FLIGHT_LIST', payload: filter_list });
                dispatch({ type: 'FLIGHT_SET_MAX_MIN_DURATION', payload: { min_duration: tmp_min_duration, max_duration: tmp_max_duration } });
            }
            setLoadingRequest(true);
        }
    }, [providers_count, searched_group]);

    useEffect(() => {
        if (searched_group !== null && countProviders === providers_count) {
            if (flight_params.length !== 0) {
                let filter_list = [...flight_list];
                let tmp_slider_time = [...slider_time];
                let tmp_min_duration = min_duration;
                let tmp_max_duration = max_duration;
                let param = flight_params[0];
                let has_manual_flight = filter_list.find((flight) => providersObj[flight.provider_id].code === 'custom_flight');
                if (param.display_manual_response_first && has_manual_flight !== undefined) {
                    filter_list = filter_list.sort((a, b) => {
                        if (a.provider_id === has_manual_flight.provider_id && b.provider_id !== has_manual_flight.provider_id) {
                            return -1;
                        }
                        if (a.provider_id === has_manual_flight.provider_id && b.provider_id === has_manual_flight.provider_id) {
                            return parseFloat(a.total_price) - parseFloat(b.total_price);
                        }
                        return 1;
                    });
                }
                if (param.hide_gds_response_on_manual_response) {
                    if (has_manual_flight !== undefined) {
                        filter_list = filter_list.filter((flight) => flight.provider_id === has_manual_flight.provider_id);
                    }
                }
                if (param.disallowed_arrival_airport !== null && param.disallowed_arrival_airport.length !== 0) {
                    let disallowed_arrival_airport_key = param.disallowed_arrival_airport.map(airport => {
                        return airport.id;
                    });
                    filter_list = filter_list.filter((flight) => {
                        for (let i = 0; i < flight.outbounds.length; i++) {
                            for (let j = 0; j < flight.outbounds[i].legs.length; j++) {
                                if (flight.outbounds[i].legs[j].origin_airport !== undefined || flight.outbounds[i].legs[j].destination_airport !== undefined) {
                                    if (disallowed_arrival_airport_key.includes(flight.outbounds[i].legs[j].origin_airport) || disallowed_arrival_airport_key.includes(flight.outbounds[i].legs[j].destination_airport)) {
                                        return false;
                                    }
                                }
                            }
                        }
                        return true;
                    });
                }
                if (param.disallowed_departure_airport !== null && param.disallowed_departure_airport.length !== 0) {
                    let disallowed_departure_airport_key = param.disallowed_departure_airport.map(airport => {
                        return airport.id;
                    });
                    filter_list = filter_list.filter((flight) => {
                        for (let i = 0; i < flight.outbounds.length; i++) {
                            for (let j = 0; j < flight.outbounds[i].legs.length; j++) {
                                if (flight.outbounds[i].legs[j].origin_airport !== undefined || flight.outbounds[i].legs[j].destination_airport !== undefined) {
                                    if (disallowed_departure_airport_key.includes(flight.outbounds[i].legs[j].origin_airport) || disallowed_departure_airport_key.includes(flight.outbounds[i].legs[j].destination_airport)) {
                                        return false;
                                    }
                                }
                            }
                        }
                        return true;
                    });
                }
                if (param.start_departure_time !== null && param.end_departure_time !== null) {
                    tmp_slider_time[0].departure[0] = param.start_departure_time;
                    tmp_slider_time[0].departure[1] = param.end_departure_time;
                }
                if (param.start_arrival_time !== null && param.end_arrival_time !== null) {
                    tmp_slider_time[0].arrival[0] = param.start_arrival_time;
                    tmp_slider_time[0].arrival[1] = param.end_arrival_time;
                }
                if (param.start_departure_time_inbound !== null && param.end_departure_time_inbound !== null) {
                    tmp_slider_time[1].departure[0] = param.start_departure_time_inbound;
                    tmp_slider_time[1].departure[1] = param.end_departure_time_inbound;
                }
                if (param.start_arrival_time_inbound !== null && param.end_arrival_time_inbound !== null) {
                    tmp_slider_time[1].arrival[0] = param.start_arrival_time_inbound;
                    tmp_slider_time[1].arrival[1] = param.end_arrival_time_inbound;
                }
                if (param.flight_duration_min !== null) {
                    tmp_min_duration = param.flight_duration_min / 60;
                }
                if (param.flight_duration_max !== null) {
                    tmp_max_duration = param.flight_duration_max / 60;
                }
                if (param.change_airport) {
                    dispatch({ type: 'FLIGHT_TOGGLE_CHANGE_AIRPORT' });
                }
                dispatch({ type: 'FLIGHT_SET_SLIDER_TIME', payload: tmp_slider_time });
                dispatch({ type: 'FLIGHT_SET_FLIGHT_LIST', payload: filter_list });
                dispatch({ type: 'FLIGHT_SET_MAX_MIN_DURATION', payload: { min_duration: tmp_min_duration, max_duration: tmp_max_duration } });
            }
            setLoadingRequest(true);
        }
    }, [providers_count, searched_group]);

    useEffect(() => {
        if (searched_group !== null && countProviders === providers_count) {
            setLoadingRequest(true);
        }
    }, [providers_count, searched_group]);
    useEffect(() => {
        if (search_providers !== null) {
            let temp_providers_obj = {};
            search_providers.map((provider) => {
                providers_list.map((full_provider) => {
                    if (full_provider.provider.id === provider.id) {
                        temp_providers_obj[provider.id] = full_provider.provider;
                    }
                });
            });
            setProvidersObj(temp_providers_obj);
        }
    }, [search_providers]);

    useEffect(() => {
        checkProviders();
    }, [providers_count]);

    moment.locale(language);

    const class_type_mapping = [
        t("flight.class.eco"),
        t("flight.class.premium_eco"),
        t("flight.class.business"),
        t("flight.class.first")
    ];

    const updateCartFromDuplication = () => {
        let nb_cart_item = (accommodation_cart?.length) + (car_cart?.length) + (flight_cart?.length) + (manual_item_list?.length);
        dispatch({ type: "MENU_FROM_DUPLICATION", payload: { from_duplication: false } });
        let trav_arr = [];
        for (let i = 0; i < traveler_groups.length; i++) {
            if (traveler_groups[i].default === true) {
                trav_arr.push(traveler_groups[i].id);
            }
        }
        if (nb_cart_item > 0 || trip?.trip?.from_circuit_id) {
            UpdateCart(
                "COMP",
                dispatch,
                null,
                null,
                trav_arr,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                adaltePackages
            );
        }
    };
    const checkProviders = () => {
        if (flight_groups[index_group] !== null) {
            let count_providers = 0;
            flight_groups[index_group].provider.map((provider) => {
                let find_provider = flight_providers.find(el => el.id === provider);
                if (find_provider !== undefined) {
                    if (find_provider.code === '1G') {
                        if (flight_groups[index_group].private_price && flight_groups[index_group].public_price) {
                            count_providers += 2;
                        } else {
                            count_providers++;
                        }
                    } else if (find_provider.code === 'amadeus_rest') {
                        if (!flight_groups[index_group].standard_price && !flight_groups[index_group].ndc_price) {
                            count_providers++;
                        } else {
                            if (flight_groups[index_group].standard_price) {
                                count_providers++;
                            }
                            if (flight_groups[index_group].ndc_price) {
                                if (!flight_groups[index_group].private_price_ndc && !flight_groups[index_group].public_price_ndc) {
                                    count_providers++;
                                } else {
                                    if (flight_groups[index_group].private_price_ndc) {
                                        count_providers++;
                                    }
                                    if (flight_groups[index_group].public_price_ndc) {
                                        count_providers++;
                                    }
                                }
                            }
                        }
                    } else {
                        count_providers++;
                    }
                }
            });
            setCountProviders(count_providers);
        }
    };
    const getPriceInfo = (journey_type) => {
        let price_info = "";
        switch (journey_type) {
            case 1:
                price_info = t("global.round_trip");
                break;
            case 2:
                price_info = t("global.multi_destination");
                break;
            case 3:
                price_info = t("global.one_way");
                break;
        }
        return price_info;
    };

    const seeMoreResults = () => {
        dispatch(SetFetchedDataStatus(null));
        GetMinimalPrices(flight_groups, flight_providers, index_group, trip_id, trip_currency, end_date, user, params.use_cache, "add_flights", dispatch);
        dispatch(SetShowMoreResults(true));
    };
    return (
        <Container maxWidth={"lg"} className={classes.spacer}>
            <Grid className={`${classes.genericText} ${classes.bold} ${isHandHeld ? classes.fontSize16 : classes.fontSize12} ${classes.uppercase}`}>
                {t("global.your_search")}
            </Grid>
            {
                flight_groups.length !== 1 && (
                    <Grid className={`${classes.genericText} ${classes.fontWeight700} ${classes.fontSize12} ${classes.uppercase}`}>
                        {t("flight_groups.group") + (index_group + 1)}
                    </Grid>
                )
            }
            {
                searched_group !== null && (
                    <Grid container alignItems="center" justify={isMobile ? "center" : "space-between"} className={classes.spacer}>
                        <Grid item xs={isHandHeld ? 12 : 7}>
                            {
                                searched_group.journey.map((flight, flight_index) => {
                                    return (
                                        <Grid key={flight_index}>
                                            <Grid container key={`${index_group}-${flight_index}`} alignItems="center" justify={isMobile ? "center" : "flex-start"} spacing={1} style={isMobile ? {} : { margin: isTablet ? "-8px 0px" : "-25px 0px" }}>
                                                <Grid item className={`${classes.genericText} ${classes.fontWeight500} ${isHandHeld ? classes.fontSize14 : classes.fontSize12}`}>
                                                    {flight.origin !== null ? flight.origin.city : ""}
                                                </Grid>
                                                <Grid item className={classes.genericIcon}>
                                                    <ArrowRightAltIcon />
                                                </Grid>
                                                <Grid item className={`${classes.genericText} ${classes.fontWeight500} ${isHandHeld ? classes.fontSize14 : classes.fontSize12}`}>
                                                    {flight.destination !== null ? flight.destination.city : ""}
                                                </Grid>
                                                <Grid item xs={isMobile ? 12 : false} className={`${classes.genericText} ${isMobile ? classes.textCenter : ""} ${classes.bold} ${isHandHeld ? classes.fontSize12 : classes.fontSize10}`}>
                                                    {moment(flight.start_date).format("dddd Do MMMM")}
                                                </Grid>
                                                <Grid item xs={isMobile ? 12 : false} className={`${classes.genericText} ${isMobile ? classes.textCenter : ""} ${classes.bold} ${isHandHeld ? classes.fontSize12 : classes.fontSize10}`}>
                                                    {class_type_mapping[flight.class_type]}
                                                </Grid>
                                            </Grid>
                                            {isMobile && (flight_index < searched_group.journey.length - 1) && <Divider />}
                                        </Grid>
                                    );
                                })
                            }
                        </Grid>
                        <Grid item xs={isHandHeld ? 6 : 3} className={`${classes.genericText} ${classes.fontWeight500} ${classes.fontSize12} ${classes.textCenter}`}>
                            {CountAgeStr(searched_group.travelers, end_date, t)}
                        </Grid>
                        {
                            !replaceProductContext.enableReplace &&
                            <Grid item xs={isHandHeld ? 6 : 2} className={isHandHeld ? classes.textCenter : classes.textRight}>
                                <Button size="small" variant="contained" className={classes.editButton} onClick={() => {
                                    dispatch(CreateFlightHours(flight_groups[index_group].journey.length));
                                    setEditSearch(!editSearch);
                                }}>
                                    <div className={`${classes.genericText} ${classes.fontWeight500} ${classes.fontSize14} ${classes.orange}`}>
                                        {t("global.edit")}
                                    </div>
                                </Button>
                            </Grid>
                        }
                    </Grid>
                )
            }
            {
                !isHandHeld && <FlightGroups origin_url={"search"} display={editSearch} setDisplay={setEditSearch} setSearchGroup={setSearchGroup} use_cache={params.use_cache} setLoadingRequest={setLoadingRequest} />
            }
            {
                isHandHeld && (
                    <Dialog open={editSearch} fullScreen TransitionComponent={Transition}>
                        <DialogTitle style={{ padding: "8px 24px" }}>
                            <Grid container alignItems="center" justify="space-between">
                                <Grid item xs={isMobile ? 2 : 1}>
                                    <IconButton className={classes.genericBorder} size="small" onClick={() => setEditSearch(false)}>
                                        <NavigateBeforeIcon />
                                    </IconButton>
                                </Grid>
                                <Grid item xs={isMobile ? 8 : 10} className={`${classes.genericText} ${classes.textCenter} ${classes.fontWeight900} ${isMobile ? classes.fontSize16 : classes.fontSize28} ${classes.orange}`}>
                                    {t("flight.edit_search")}
                                </Grid>
                                <Grid item xs={isMobile ? 2 : 1} />
                            </Grid>
                        </DialogTitle>
                        <Divider />
                        <DialogContent style={{ padding: 0, overflowX: "hidden" }}>
                            <FlightGroups origin_url={"search"} display={editSearch} setDisplay={setEditSearch} setSearchGroup={setSearchGroup} use_cache={params.use_cache} setLoadingRequest={setLoadingRequest} />
                        </DialogContent>
                    </Dialog>
                )
            }
            {
                searched_group !== null && (
                    <Grid container alignItems="center" justify="flex-end" direction={isHandHeld ? "column" : "row"} spacing={isHandHeld ? 2 : 0}>
                        <Grid item xs={isHandHeld ? 12 : (fetched_status !== null ? 8 : 12)} className={`${classes.genericText} ${classes.bold} ${isHandHeld ? classes.fontSize12 : classes.fontSize10} ${fetched_status !== null && !isMobile ? classes.textRight : classes.textCenter}`}>
                            {`${t("flight_search.price_info_intro")} ${getPriceInfo(searched_group.journey_type)} ${t("flight_search.price_info_end")}`}
                        </Grid>
                        {
                            fetched_status !== null && (
                                <Grid item xs={isHandHeld ? 12 : 4} className={`${classes.genericText} ${classes.fontWeight500} ${isHandHeld ? classes.fontSize16 : classes.fontSize12} ${classes.textRight}`}>
                                    {flight_list_render.length + " " + t("flight_search.flight_found")}
                                </Grid>
                            )
                        }
                    </Grid>
                )
            }
            <Divider />
            {
                fetched_status !== null && flight_list.length !== 0 && searched_group !== null && (
                    <FiltersBar searched_group={searched_group} />
                )
            }
            {
                fetched_status !== null && searched_group !== null && loadingRequest && flight_list_render.length === 0 && (
                    <Grid className={`${classes.skeletonPadding} ${classes.textCenter} ${classes.genericText} ${classes.fontWeight700} ${classes.fontSize14}`}>
                        {t("flight_search.error.no_availability")}
                    </Grid>
                )
            }
            {
                searched_group !== null && searched_group.flexible_date && matrix_status !== null && (
                    <FlightMatrix searched_group={searched_group} setSearchGroup={setSearchGroup} setLoadingRequest={setLoadingRequest} />
                )
            }
            {
                (fetched_status === null || (searched_group !== null && !loadingRequest)) && (
                    <FlightSearchSkeleton />
                )
            }
            {
                fetched_status !== null && searched_group !== null && loadingRequest && <FlightList searched_group={searched_group} providersObj={providersObj} router={router} />
            }
            {
                // fetched_status !== null && searched_group !== null && show_more_results === false && (
                //     <Grid className={classes.textCenter}>
                //         <Button variant="contained" className={classes.moreResultsButton} onClick={seeMoreResults}>
                //             { t("flight_search.see_more_results") }
                //         </Button>
                //     </Grid>
                // )
            }
            {/* <CartFlightPriceDetail/> */}
        </Container>
    );
});

export default React.memo(FlightSearch);
