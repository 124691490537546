import React, { useState } from "react"
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core";

import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import WbSunnyIcon from '@material-ui/icons/WbSunny';
import NightsStayIcon from '@material-ui/icons/NightsStay';
import WorkIcon from "@material-ui/icons/Work";
import WorkOffIcon from '@material-ui/icons/WorkOff';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';

import { ToggleDetail } from "../../../Actions/FlightSearch";

import ConvertTime from "../Functions/ConvertTime";

import Transition from "../../Common/Transition";
import ArrowIcon from "./ArrowIcon";
import OutboundDetails from "./OutboundDetails";

import moment from "moment";


const useStyles = makeStyles(() => ({
    genericText: {
        fontFamily: "Roboto",
        fontStyle: "normal",
        color: '#0000008A',
        letterSpacing: 1.25
    },
    fontWeight500: {
        fontWeight: 500
    },
    fontWeight900: {
        fontWeight: 900
    },
    fontSize9: {
        fontSize: 9,
    },
    fontSize12: {
        fontSize: 12
    },
    fontSize16: {
        fontSize: 16
    },
    fontSize28: {
        fontSize: 28
    },
    red: {
        color: "red"
    },
    orange: {
        color: "#E6592F"
    },
    lightgrey: {
        color: '#00000040'
    },
    white: {
        color: "white"
    },
    uppercase: {
        textTransform: "uppercase"
    },
    textCenter: {
        textAlign: "center"
    },
    borderTableCell:{
        borderBottom: "none"
    },
    paddingIcon: {
        paddingTop: 4
    },
    arrow: {
        fontSize: 100,
        margin: -46
    },
    filterButton:{
        border: "1.03872px solid #DDDDDD",
        boxSizing: "border-box",
        borderRadius: 31.1617,
        padding: "8px 12px"
    },
    filterButtonSmall:{
        border: "1.03872px solid #DDDDDD",
        boxSizing: "border-box",
        borderRadius: 31.1617
    },
    durationDaysPosition: {
        position: "relative",
        bottom: 50,
        left: 54
    },
    durationDaysPositionSmall: {
        position: "relative",
        bottom: 37,
        left: 27
    }
}))

const OutboundCardMobile = ({ flight_index, outbound_flight, outbound_index, travelers, max_index, small, flight_id }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const language = useSelector(store => store.header.language);
    const airlines = useSelector(store => store.flight_search.airlines);
    const airports = useSelector(store => store.flight_search.airports);
    const stations = useSelector(store => store.flight_search.stations);

    const flight_list_render = useSelector(store => store.flight_search.flight_list_render);
    const airlineObj = airlines.find(item => item.id === outbound_flight.legs[0].marketing_airline);
    const [showDetails, setShowDetails] = useState(false);
    
    moment.locale(language);

    const GetStopOverCountry = () => {
        let countries = [];
        outbound_flight.legs.map((leg, leg_index) => {
            if(leg_index > 0){
                if (leg.origin_airport !== undefined && airports[leg.origin_airport] !== undefined) {
                    countries.push(airports[leg.origin_airport].iata_city.iata_country.country_code_alpha3);
                }else if (leg.origin_station !== undefined && stations[leg.origin_station] !== undefined) {
                    countries.push(stations[leg.origin_station].iata_city.iata_country.country_code_alpha3);
                }
            }
        })
        let countries_str = countries.join(", ");
        if (countries_str === "")
            return countries_str
        else
            return "(" + countries_str + ")"
	}

    const GetStopOverCountryTime = (leg, leg_index) => {
        let country_code = ""
        if (leg.origin_airport !== undefined && airports[leg.origin_airport] !== undefined) {
            country_code = airports[leg.origin_airport].iata_city.iata_country.country_code_alpha3;
        }else if (leg.origin_station !== undefined && stations[leg.origin_station] !== undefined) {
            country_code = stations[leg.origin_station].iata_city.iata_country.country_code_alpha3;
        }
        let duration = "";
        if (outbound_flight.stop_over_duration !== undefined){
            duration = "(" + ConvertTime(outbound_flight.stop_over_duration[leg_index - 1], "seconds", language, t) + ")";
        }
        return country_code + duration
	}

    const OpenDetails = () => {
        dispatch(ToggleDetail(flight_id, outbound_index, showDetails));
        setShowDetails(!showDetails);
    }

   return(
       
       <Grid>
            <TableContainer style={{ height: 200, overflow: "hidden" }}>
                <Table>
                    <TableBody>
                        <TableRow>
                            {
                                airlineObj !== null && airlineObj !== undefined && (
                                    <TableCell padding="none" className={`${classes.textCenter} ${classes.borderTableCell}`} style={{ width : 90 }}>
                                        <img src={ "https://facilitatrip-staging-picture.s3.eu-west-3.amazonaws.com/iata-company/" + airlineObj.code + ".png" } alt={ "provider logo" } style={{ maxWidth: 88, maxHeight: 25 }} />
                                        <div className={`${classes.genericText} ${classes.fontWeight900} ${classes.fontSize9} ${classes.lightgrey}`}>
                                            { t("flight_search.operate_by") + airlineObj.name }
                                        </div>
                                    </TableCell>
                                )
                            }
                            {
                                airlineObj !== null && airlineObj !== undefined && (
                                    <TableCell padding="none" className={`${classes.textCenter} ${classes.borderTableCell}`} style={{ width: 170 }}>
                                        {
                                            flight_list_render[flight_index].name === null && outbound_flight.legs.map((leg) => {
                                                return (
                                                    <div key={leg.marketing_flight_number} className={`${classes.genericText} ${classes.fontWeight900} ${classes.fontSize12}`}>
                                                        { leg.marketing_flight_number !== undefined && `${airlineObj.code}-${leg.marketing_flight_number}` }
                                                        { leg.marketing_flight_number === undefined && leg.flight_number !== undefined && `${airlineObj.code}-${leg.flight_number}` }
                                                    </div>
                                                )
                                            })
                                        }
                                    </TableCell>
                                )
                            }
                            <TableCell padding="none" className={`${classes.textCenter} ${classes.borderTableCell}`} style={{ width: small ? 165 : 180 }}>
                                <Button size="small" className={small ? classes.filterButtonSmall : classes.filterButton} onClick={OpenDetails}>
                                    <div className={`${classes.genericText} ${classes.fontWeight900} ${small ? classes.fontSize9 : classes.fontSize12} ${classes.uppercase}`} style={{ paddingLeft: 8 }}>
                                        { t("global.detail") }
                                    </div>
                                    {
                                        showDetails ? <ArrowDropUpIcon className={classes.genericText} /> : <ArrowDropDownIcon className={classes.genericText} />
                                    }
                                </Button>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell padding="none" className={`${classes.textCenter} ${classes.borderTableCell}`} style={{ width: small ? 90 : 100 }}>
                                {
                                    !outbound_flight.no_time_data && (
                                        <Grid className={`${classes.genericText} ${classes.fontWeight900} ${small ? classes.fontSize12 : classes.fontSize16}`}>
                                            { language === "fr" ? moment(outbound_flight.legs[0].departure_datetime_lt).format("LT").replace(":", "h") : moment(outbound_flight.legs[0].departure_datetime_lt).format("LT") }
                                        </Grid>
                                    )
                                }
                                {
                                    outbound_flight.legs[0].origin_airport !== undefined && airports[outbound_flight.legs[0].origin_airport] && (
                                        <div className={`${classes.genericText} ${classes.fontWeight900} ${small ? classes.fontSize9 : classes.fontSize12} ${classes.uppercase}`}>
                                            { airports[outbound_flight.legs[0].origin_airport].iata_city.name !== undefined ? airports[outbound_flight.legs[0].origin_airport].iata_city.name : airports[outbound_flight.legs[0].origin_airport].iata_city.international_name }
                                            { `(${airports[outbound_flight.legs[0].origin_airport].code})` }
                                        </div>
                                    )
                                }
                                {
                                    outbound_flight.legs[0].origin_station !== undefined && stations[outbound_flight.legs[0].origin_station] && (
                                        <div className={`${classes.genericText} ${classes.fontWeight900} ${small ? classes.fontSize9 : classes.fontSize12} ${classes.uppercase}`}>
                                            { stations[outbound_flight.legs[0].origin_station].iata_city.name !== undefined ? stations[outbound_flight.legs[0].origin_station].iata_city.name : stations[outbound_flight.legs[0].origin_station].iata_city.international_name }
                                            { `(${stations[outbound_flight.legs[0].origin_station].code})` }
                                        </div>
                                    )
                                }
                            </TableCell>
                            <TableCell padding="none" className={`${classes.textCenter} ${classes.borderTableCell}`} style={{ width: small ? 110 : 140 }}>
                                {
                                    !outbound_flight.no_time_data && (
                                        <div className={`${classes.genericText} ${classes.fontWeight500} ${classes.fontSize9} ${classes.lightgrey} ${classes.uppercase}`}>
                                            { ConvertTime(outbound_flight.flight_duration, "seconds", language, t) }
                                        </div>
                                    )
                                }
                                {/* <div className={"departure-arrival-time col-md-4 col-sm-4"}>
                                    <div className={ small ? "icon-arrow-destination-travel result-information-recap" : "icon-arrow-destination-travel result-information" }/>
                                </div> */}
                                <div>
                                    <ArrowIcon className={classes.arrow} />
                                </div>
                                {
                                    small === false && (
                                        <Grid container alignItems='center' justify="center" spacing={1}>
                                            <Grid item>
                                                {
                                                    outbound_flight.is_nightly ? <NightsStayIcon className={`${classes.paddingIcon} ${classes.fontSize16} ${classes.lightgrey}`} /> : <WbSunnyIcon className={`${classes.paddingIcon} ${classes.fontSize16} ${classes.lightgrey}`} />
                                                }
                                            </Grid>
                                            <Grid item className={`${classes.genericText} ${classes.fontWeight500} ${classes.fontSize9} ${classes.lightgrey} ${classes.uppercase}`}>
                                                { outbound_flight.is_nightly ? t("flight_search.night_flight") : t("flight_search.day_flight") }
                                            </Grid>
                                        </Grid>
                                    )
                                }
                                {
                                    small && (
                                        <Grid>
                                            <div className={`${classes.genericText} ${classes.fontWeight900} ${small ? classes.fontSize9 : classes.fontSize16} ${classes.uppercase} ${classes.lightgrey}`}>
                                                { outbound_flight.legs.length < 2 ? t("flight_search.direct_flight") : (outbound_flight.legs.length - 1) + " " + (outbound_flight.legs.length - 1 > 1 ? t("flight_search.stopovers") : t("flight_search.stopover")) }
                                            </div>
                                            <div className={`${classes.genericText} ${classes.fontWeight900} ${classes.fontSize9} ${classes.lightgrey}`}>
                                                { GetStopOverCountry() }
                                            </div>
                                        </Grid>
                                    )
                                }
                            </TableCell>
                            <TableCell padding="none" className={`${classes.textCenter} ${classes.borderTableCell}`} style={{ width: small ? 120 : 150 }}>
                                {
                                    !outbound_flight.no_time_data && (
                                        <Grid className={`${classes.genericText} ${classes.fontWeight900} ${small ? classes.fontSize12 : classes.fontSize16}`}>
                                            { language === "fr" ? moment(outbound_flight.legs.slice(-1)[0].arrival_datetime_lt).format("LT").replace(":", "h") : moment(outbound_flight.legs.slice(-1)[0].arrival_datetime_lt).format("LT") }
                                        </Grid>
                                    )
                                }
                                {
                                    outbound_flight.legs.slice(-1)[0].destination_airport !== undefined && airports[outbound_flight.legs.slice(-1)[0].destination_airport] && (
                                        <div className={`${classes.genericText} ${classes.fontWeight900} ${small ? classes.fontSize9 : classes.fontSize12} ${classes.uppercase}`}>
                                            { airports[outbound_flight.legs.slice(-1)[0].destination_airport].iata_city.name !== null ? airports[outbound_flight.legs.slice(-1)[0].destination_airport].iata_city.name : airports[outbound_flight.legs.slice(-1)[0].destination_airport].international_name }
                                            { `(${airports[outbound_flight.legs.slice(-1)[0].destination_airport].code})` }
                                        </div>
                                    )
                                }
                                {
                                    outbound_flight.legs.slice(-1)[0].destination_station !== undefined && stations[outbound_flight.legs.slice(-1)[0].destination_station] && (
                                        <div className={`${classes.genericText} ${classes.fontWeight900} ${small ? classes.fontSize9 : classes.fontSize12} ${classes.uppercase}`}>
                                            { stations[outbound_flight.legs.slice(-1)[0].destination_station].iata_city.name !== null ? stations[outbound_flight.legs.slice(-1)[0].destination_station].iata_city.name : stations[outbound_flight.legs.slice(-1)[0].destination_station].international_name }
                                            { `(${stations[outbound_flight.legs.slice(-1)[0].destination_station].code})` }
                                        </div>
                                    )
                                }
                                {
                                    outbound_flight.duration_days !== undefined && outbound_flight.duration_days !== 0 && (
                                        <Grid className={`${classes.genericText} ${classes.fontSize9} ${classes.red} ${small ? classes.durationDaysPositionSmall : classes.durationDaysPosition}`}>
                                            { "+" + outbound_flight.duration_days + t("global.abrev_day")}
                                            {
                                                !small && (
                                                    <span className={`${classes.genericText} ${classes.lightgrey}`}>
                                                        { ` (${moment(outbound_flight.legs.slice(-1)[0].arrival_datetime_lt).format("Do MMM")})` }
                                                    </span>
                                                )
                                            }
                                        </Grid>
                                    )
                                }
                            </TableCell>
                        </TableRow>
                        <TableRow>
                        <TableCell padding="none" className={`${classes.textCenter} ${classes.borderTableCell}`} style={{ width: small ? 30 : 115 }}>
                                {
                                    outbound_flight.legs[0].aircraft !== undefined && (
                                        <div className={`${classes.genericText} ${classes.fontWeight900} ${classes.fontSize9} ${classes.uppercase}`}>
                                            { outbound_flight.legs[0].aircraft.commercial_name }
                                        </div>
                                    )
                                }
                                {
                                    outbound_flight.legs[0].baggage_allowance && outbound_flight.legs[0].baggage_allowance.number_of_pieces > 0 && (
                                        <Grid container alignItems='center' justify="center">
                                            <Grid item>
                                                <WorkIcon className={`${classes.orange} ${classes.fontSize28}`} />
                                            </Grid>
                                            <Grid item className={`${classes.genericText} ${small ? classes.fontSize9 : classes.fontSize12} ${classes.white}`} style={{ marginLeft: -20 }}>
                                                { "x" + outbound_flight.legs[0].baggage_allowance.number_of_pieces }
                                            </Grid>
                                        </Grid>
                                    )
                                }
                                {
                                    outbound_flight.legs[0].baggage_allowance && outbound_flight.legs[0].baggage_allowance.number_of_pieces === 0 && (
                                        <WorkOffIcon className={classes.fontSize28} style={{ color: "#989998" }} />
                                    )
                                }
                                {
                                    small && outbound_flight.legs[0].cabin_class !== undefined && (
                                        <div className={`${classes.genericText} ${classes.fontWeight900} ${classes.fontSize9} ${classes.uppercase}`}>
                                            { t(`flight_search.cabin_class_short.${outbound_flight.legs[0].cabin_class.replaceAll(' ', '_').toLowerCase()}`) }
                                        </div>
                                    )
                                }
                            </TableCell>
                            {
                                small === false && (
                                    <TableCell padding="none" className={`${classes.textCenter} ${classes.borderTableCell}`} style={{ width: 115 }}>
                                        <div className={`${classes.genericText} ${classes.fontWeight900} ${classes.fontSize12} ${classes.uppercase}`}>
                                            { t(`flight_search.cabin_class.${outbound_flight.legs[0].cabin_class.replaceAll(' ', '_').toLowerCase()}`) }
                                        </div>
                                        <div className={`${classes.genericText} ${classes.fontWeight900} ${classes.fontSize9} ${classes.lightgrey}`}>
                                            { outbound_flight.legs[0].booking_code }
                                        </div>
                                    </TableCell>
                                )
                            }
                            {
                                small === false && (
                                    <TableCell padding="none" className={`${classes.textCenter} ${classes.borderTableCell}`} style={{ width: 115 }}>
                                        <div className={`${classes.genericText} ${classes.fontWeight900} ${classes.fontSize16} ${classes.uppercase}`}>
                                            { outbound_flight.legs.length < 2 ? t("flight_search.direct_flight") : (outbound_flight.legs.length - 1) + " " + (outbound_flight.legs.length - 1 > 1 ? t("flight_search.stopovers") : t("flight_search.stopover")) }
                                        </div>
                                        {
                                            outbound_flight.legs.map((leg, leg_index) => {
                                                if (leg_index > 0){
                                                    return(
                                                        <div key={leg_index} className={`${classes.genericText} ${classes.fontWeight900} ${classes.fontSize9} ${classes.lightgrey}`}>
                                                            { GetStopOverCountryTime(leg, leg_index) }
                                                        </div>
                                                    )
                                                }
                                            })
                                        }
                                        {
                                            flight_list_render[flight_index].name === null && (
                                                <Grid className={`${classes.genericText} ${classes.fontWeight900} ${small ? classes.fontSize9 : classes.fontSize12} ${classes.orange} ${classes.uppercase}`} style={{ paddingTop: 8 }}>
                                                    { outbound_flight.available_seats + " " }
                                                    { outbound_flight.available_seats > 1 ? t("flight_search.available_seats") : t("flight_search.available_seats") }
                                                </Grid>
                                            )
                                        }
                                    </TableCell>
                                )
                            }
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
            <Dialog open={showDetails} fullScreen TransitionComponent={Transition}>
                <DialogContent style={{ padding: 0, overflowX: "hidden" }}>
                    <OutboundDetails setShowDetails={setShowDetails} outbound_flight={outbound_flight} outbound_index={outbound_index} travelers={travelers} max_index={max_index} small={small} />
                </DialogContent>
            </Dialog>
        </Grid>
    )
}

export default React.memo(OutboundCardMobile);