import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import {
    Alert,
    Backdrop,
    Box,
    Button,
    Divider,
    Grid,
    IconButton,
    List,
    ListItem,
    Stack,
    Switch,
    Tooltip,
    Typography
} from "@mui/material";
import {
    Close,
    ShoppingCartCheckoutOutlined
} from "@mui/icons-material";
import { useSnackbar } from "notistack";
import { cloneDeep, debounce, findLastIndex, isNumber, isString, uniq } from "lodash";
import axios, { AxiosResponse } from "axios";
import tzlookup from "@photostructure/tz-lookup";
import { ItineraryStepItem } from "./itineraryStepItem";
import { ItineraryTransportItem } from "./itineraryTransportItem";
import { ItineraryStepItemDragLayer } from "./itineraryStepItemDragLayer";
import { ItineraryTransportChooseModal } from "./itineraryTransportChooseModal";
import { ItineraryTransportItemAddInput } from "./itineraryTransportItemAddInput";
import { ItineraryStepItemDropTarget } from "./itineraryStepItemDropTarget";
import { ItineraryLockBox } from "./itineraryLockBox";
import { ItineraryInvertStepsButton } from "./itineraryInvertStepsButton";
import { ItineraryBlocksTravelExchangeModal } from "./itineraryBlocksTravelExchangeModal";
import HeaderPassenger from "../Header/HeaderPassenger";
import { ItineraryContext } from "./utils/itineraryContext";
import { StepsDirectionsManager } from "./utils/stepsDirectionsManager";
import { StepsDatesManager } from "./utils/stepsDatesManager";
import { getLightDestinationName } from "./utils/getLightDestinationName";
import { areStepsEqual } from "./utils/areStepsEqual";
import { filterFlightAlternatives } from "./utils/filterFlightAlternatives";
import { takeBestTransport } from "./utils/takeBestTransport";
import { getCircuit } from "./utils/getCircuit";
import { getTrip } from "./utils/getTrip";
import { sortItinerary } from "./utils/sortItinerary";
import { itineraryToItineraryInput } from "./utils/itineraryToItineraryInput";
import { transformStepInputsToGroups } from "./utils/transformStepInputsToGroups";
import { extractStepsForContentByDay, extractStepsForContentByStep } from "./network/itineraryContent";
import { makeContentUpdateRequest } from "./network/itineraryContentUpdate";
import GetItineraryFlight from "./InfoReload/GetItineraryFlight";
import { useItineraryUpdate } from "./network/itineraryUpdate";
import { useItineraryTripVersionUpdate } from "./network/itineraryTripVersionUpdate";
import { useItineraryStepAdd } from "./network/itineraryStepAdd";
import { useShowError } from "../Utils/showError";
import { useItineraryBlockAdd } from "./network/itineraryBlockAdd";
import { SaveRequestsQueue } from "./utils/saveRequestsQueue";
import CheckBeforeRequest from "../Common/CheckBeforeRequest";
import GetCookie from "../Common/Functions/GetCookie";
import {
    SetDepartureDestination,
    SetItinerary,
    SetReturnDestination
} from "../../Actions/Itinerary";
import {
    changeStepTransport,
    createInitialSteps,
    distributeBlockIds,
    emptyLastStepTransport,
    fixInputsAnomalies,
    removeInitialSteps,
    setAdalteModalAddOptions,
    setBeingCalculatedStep,
    setDefaultArrivalHourlyForTerrestrialOnlyMode,
    setIsImportingTexts,
    setItineraryContent,
    setItineraryStartDate,
    setLastEndStep,
    setLastStartStep,
    setLoading,
    setLockboxes,
    setShouldNormalizeTexts,
    setStepsInputs,
    setTravelExchangeModalAddOptions
} from "./redux/reducer";
import { SetDataTrip, SetDates } from "../../Actions/Trip";
import { setStatus } from "../Map/redux/reducer";
import { ItineraryInput } from "./objects/itineraryState";
import { Transport } from "./objects/transport";
import { GeoFlashDestination } from "./objects/geoFlashDestination";
import { Itinerary } from "./objects/itinerary";
import { Place, Route } from "./objects/r2rSearchResponse";
import { Circuit } from "./objects/circuit";
import { Trip } from "../Menu/MaterialTripList/objects/trip";
import { LockBox } from "./objects/lockBox";
import { FlashDestination } from "./objects/flashDestination";
import { ItineraryByDay } from "./objects/itineraryByDay";
import { ItineraryByStep } from "./objects/itineraryByStep";
import { AppState } from "../../Reducers/Reducers";

type Props = {
    openTransportModification: boolean,
    onToggleTransportModification: (open: boolean) => void
}

export function ItinerarySteps(props: Props): JSX.Element {
    const { t, i18n } = useTranslation();
    const dispatch = useDispatch();
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const locale = useSelector((state: AppState) => state.user.locales?.find((item) => {
        return item.language_code === i18n.language;
    })?.id ?? 1);
    const steps = useSelector((state: AppState) => state.itinerary.itinerary_list);
    const trip = useSelector((state: AppState) => state.trip.all_data);
    const tripVersion = useSelector((state: AppState) => state.trip.data_trip);
    const tripId = useSelector((state: AppState) => state.trip.trip_id);
    const tripStartDate = useSelector((state: AppState) => state.trip.start_date);
    const tripEndDate = useSelector((state: AppState) => state.trip.end_date);
    const stepsInputs = useSelector((state: AppState) => state.itinerarySlice.stepsInputs);
    const map = useSelector((state: AppState) => state.itinerarySlice.map);
    const lockBoxes = useSelector((state: AppState) => state.itinerarySlice.blocks.lockBoxes);
    const adalteModalAddOptions = useSelector((state: AppState) => state.itinerarySlice.adalteModalAddOptions);
    const travelExchangeModalAddOptions = useSelector((state: AppState) => state.itinerarySlice.travelExchangeModalAddOptions);
    const shouldNormalizeTexts = useSelector((state: AppState) => state.itinerarySlice.shouldNormalizeTexts);
    const isCalculatingAtIndexZero = useSelector((state: AppState) => {
        return !!state.itinerarySlice.calculatingTransportAtIndex[-1];
    });
    const isConstructionMode = useSelector((state: AppState) => !!state.trip.all_data?.circuit);
    const [selectedStepsForTransportChange, setSelectedStepsForTransportChange] = useState<
        [ItineraryInput, ItineraryInput] | null
    >(null);
    const version = useSelector((state: AppState) => state.menu.to_show_version);
    const lastSteps = useRef<Itinerary[]>([]);
    const [transports, setTransports] = useState<Transport[]>([]);
    const [loadingTransports, setLoadingTransports] = useState(false);
    const [refreshCart, setRefreshCart] = useState(false);
    const [resetTransportsFor, setResetTransportsFor] = useState<[ItineraryInput['id'], ItineraryInput['id']][]>([]);
    const [circuits, setCircuits] = useState<Circuit[]>([]);
    const [trips, setTrips] = useState<Trip[]>([]);
    const [loadingDestination, setLoadingDestination] = useState(false);
    const [chooseTransportModalSteps, setChooseTransportModalSteps] = useState<[ItineraryInput, ItineraryInput] | null>(null);
    const saveQueue = useMemo(() => {
        return new SaveRequestsQueue<Itinerary, ItineraryInput>();
    }, []);
    const context = useContext(ItineraryContext);
    const containerRef = useRef<HTMLDivElement>(null);
    const mountedRef = useRef(false);
    const listRef = useRef<HTMLUListElement>(null);
    const fetchedItinerary = useRef(false);
    const [container, setContainer] = useState<HTMLDivElement | null>(null);
    const [addingBlock, setAddingBlock] = useState(false);
    const showError = useShowError();
    const addBlock = useItineraryBlockAdd();
    const update = useItineraryUpdate({
        onTrigger() {
            dispatch(setStatus('saving'));
        },
        onError(error) {
            if (!isString(error) && !axios.isCancel(error)) {
                showError(error);
            }
        },
        onFinally() {
            dispatch(setStatus('idle'));
        }
    });
    const updateTripVersion = useItineraryTripVersionUpdate({
        onError(error) {
            showError(error);
        }
    });
    const addStep = useItineraryStepAdd({});

    const circuitGrouppedSteps = transformStepInputsToGroups(stepsInputs);

    const onAutoRecompute = useCallback(
        debounce(
            async (
                options: {
                    map: google.maps.Map | null,
                    tripStartDate: string | null,
                    tripEndDate: string | null,
                    inputs: ItineraryInput[],
                    tripId: number | null,
                    isDragging: boolean,
                    signal: AbortSignal
                }
            ) => {
                if (
                    options.map &&
                    options.tripStartDate &&
                    options.tripEndDate &&
                    !options.isDragging
                ) {
                    const datesManager = new StepsDatesManager(
                        options.tripStartDate,
                        options.tripEndDate
                    );
                    const directionsManager = StepsDirectionsManager.getInstance();

                    try {
                        const result = [...options.inputs];

                        if (
                            !await directionsManager.areTransportsAllValid(result) ||
                            !datesManager.areDatesAllValid(result)
                        ) {
                            //recompute transports and dates

                            for (let i = 0; i < result.length; i++) {
                                options.signal.throwIfAborted();
                                const current = cloneDeep(result[i]!);
                                const next = result[i + 1];

                                if (next) {
                                    options.signal.throwIfAborted();
                                    dispatch(setBeingCalculatedStep(current));
                                    options.signal.throwIfAborted();
                                    const transportsPair = await directionsManager.recomputeTransportsBetween(current, next);
                                    options.signal.throwIfAborted();
                                    const resultPair = datesManager.recomputeDates(transportsPair[0], transportsPair[1]);
                                    options.signal.throwIfAborted();
                                    result[i] = resultPair[0];
                                    result[i + 1] = resultPair[1];
                                }
                            }

                            options.signal.throwIfAborted();
                            dispatch(setStepsInputs(result));
                            dispatch(setBeingCalculatedStep(null));
                        }
                    } catch (error: any) {
                        if (error !== 'Aborted autocalculation.') {
                            throw error;
                        }
                    } finally {
                        dispatch(setStatus('idle'));
                        dispatch(setBeingCalculatedStep(null));
                    }
                }
            },
            1000
        ),
        []
    );
    const datesManager = new StepsDatesManager(
        tripStartDate ?? new Date().toISOString(),
        tripEndDate ?? new Date().toISOString()
    );

    const onChangeTransport = async (a: ItineraryInput, b: ItineraryInput) => {
        if (map) {
            props.onToggleTransportModification(true);
            setChooseTransportModalSteps([a, b]);
            setSelectedStepsForTransportChange([a, b]);
            setLoadingTransports(true);
            const manager = StepsDirectionsManager.getInstance();
            try {
                const transports = await manager.findAvailableTransports(a, b);
                setTransports(filterFlightAlternatives(a, transports));
            } catch (error: any) {
                console.error(error);
            } finally {
                setLoadingTransports(false);
            }
            setLoadingTransports(false);
        }
    };

    const onAddStep = async (index: number, destination: GeoFlashDestination | FlashDestination) => {
        setLoadingDestination(true);
        await addStep(
            {
                destination_id: destination.destination_id,
                international_name: destination.international_name ?? '',
                suggested_hours: destination.suggested_hours
            }, 
            index
        );
        setLoadingDestination(false);
    };

    const onChangeStepTransport = async (
        transport: Transport['alternatives'][number],
        r2rPlaces: Place[],
        r2rRoutes: Route[],
        isCustom: boolean
    ) => {
        if (selectedStepsForTransportChange) {
            const manager = StepsDirectionsManager.getInstance();
            const aPosition = await manager.transformStepToCoordinates(selectedStepsForTransportChange[0]);
            const bPosition = await manager.transformStepToCoordinates(selectedStepsForTransportChange[1]);
            dispatch(
                changeStepTransport({
                    step: selectedStepsForTransportChange[0],
                    nextStep: selectedStepsForTransportChange[1],
                    fromTimezone: tzlookup(aPosition.lat(), aPosition.lng()),
                    toTimezone: tzlookup(bPosition.lat(), bPosition.lng()),
                    nextStepCoordinates: {
                        latitude: bPosition.lat(),
                        longitude: bPosition.lng()
                    },
                    transport,
                    r2rPlaces,
                    r2rRoutes,
                    isCustom
                })
            );
            props.onToggleTransportModification(false);
        }
    };

    const onToggleStartingPoint = async (checked: boolean) => {
        if (tripId) {
            dispatch(setLoading(true));

            const trip = await updateTripVersion(
                tripId,
                parseInt(GetCookie('trip_id_version') ?? '-1'),
                { hasTripStartingPoint: checked }
            );

            if (!checked) {
                dispatch(setDefaultArrivalHourlyForTerrestrialOnlyMode());
            }

            if (trip) {
                dispatch(SetDataTrip(trip));
            }

            dispatch(setLoading(false));
        }
    };

    const onToggleCartUpdate = (checked: boolean) => {
        setRefreshCart(checked);
    };

    const onResetTransport = async (a: ItineraryInput, b: ItineraryInput) => {
        setResetTransportsFor((state) => {
            const itemIndex = state.findIndex(([aId, bId]) => {
                return aId === a.id && bId === b.id;
            });

            if (itemIndex < 0) {
                return state.concat([[a.id, b.id]]);
            }

            return state;
        });
        const manager = StepsDirectionsManager.getInstance();
        const availableTransports = filterFlightAlternatives(
            a,
            await manager.findAvailableTransports(a, b)
        ).map((item) => {
            return {
                ...item,
                alternatives: item.alternatives.filter((item) => {
                    return !item.disabled;
                })
            };
        });
        const defaultTransport = takeBestTransport(a, b, availableTransports);
        if (defaultTransport) {
            const aPosition = await manager.transformStepToCoordinates(a);
            const bPosition = await manager.transformStepToCoordinates(b);
            dispatch(
                changeStepTransport({
                    step: a,
                    nextStep: b,
                    fromTimezone: tzlookup(aPosition.lat(), aPosition.lng()),
                    toTimezone: tzlookup(bPosition.lat(), bPosition.lng()),
                    nextStepCoordinates: {
                        latitude: bPosition.lat(),
                        longitude: bPosition.lng()
                    },
                    transport: defaultTransport.alternative,
                    r2rPlaces: defaultTransport.transport.r2rPlaces,
                    r2rRoutes: defaultTransport.transport.r2rRoutes,
                    isCustom: false
                })
            );
        }
        setResetTransportsFor((state) => {
            return state.filter(([aId, bId]) => {
                return aId !== a.id || bId !== b.id;
            });
        });
    };

    const onUpdateLockBoxes = (listHeight: number) => {
        if (container) {
            const result = circuitGrouppedSteps.map((group, groupIndex) => {
                const circuit = circuits.find((item) => {
                    return item.id === group[0]?.circuit;
                });
                const trip = trips.find((item) => {
                    return item.id === group[0]?.iti_type;
                });
                return { circuit, circuitVersion: group[0]?.circuit_trip_version ?? null, trip, group: groupIndex };
            }).map(({ circuit, circuitVersion, trip, group }): LockBox | undefined => {
                const firstElement: HTMLLIElement | null = container.querySelector(
                    `[data-step-position="first"][data-group="${group}"]`
                );
                const lastElement: HTMLLIElement | null = container.querySelector(
                    `[data-step-position="last"][data-group="${group}"]`
                );
                const singleElement: HTMLLIElement | null = container.querySelector(
                    `[data-step-position="both"][data-group="${group}"]`
                );

                if (firstElement && lastElement) {
                    const height = listHeight -
                        firstElement.offsetTop -
                        (
                            listHeight -
                            lastElement.offsetTop -
                            lastElement.offsetHeight
                        ) +
                        10;

                    if (circuit) {
                        return {
                            type: circuit.type.toLowerCase() as 'circuit' | 'package',
                            circuit,
                            circuitVersion,
                            locked: (
                                circuit.source !== 'GESTOUR' ||
                                !isConstructionMode
                            ) &&
                                (
                                    !!circuit.trip?.lock_itinerary ||
                                    circuit.source !== 'OFFLINE'
                                ),
                            group,
                            height,
                            top: firstElement.offsetTop - 5
                        };
                    } else if (trip) {
                        return {
                            type: 'typical-trip',
                            trip,
                            locked: trip.lock_itinerary,
                            group,
                            height,
                            top: firstElement.offsetTop - 5
                        };
                    }
                } else if (singleElement) {
                    const height = listHeight -
                        singleElement.offsetTop -
                        (
                            listHeight -
                            singleElement.offsetTop -
                            singleElement.offsetHeight
                        ) +
                        10;

                    if (circuit) {
                        return {
                            type: circuit.type.toLowerCase() as 'circuit' | 'package',
                            circuit,
                            circuitVersion,
                            locked: (
                                circuit.source !== 'GESTOUR' ||
                                !isConstructionMode
                            ) &&
                                (
                                    !!circuit.trip?.lock_itinerary ||
                                    circuit.source !== 'OFFLINE'
                                ),
                            group,
                            height,
                            top: singleElement.offsetTop - 5
                        };
                    } else if (trip) {
                        return {
                            type: 'typical-trip',
                            trip,
                            locked: trip.lock_itinerary,
                            group,
                            height,
                            top: singleElement.offsetTop - 5
                        };
                    }
                }
            }).filter((item) => item) as LockBox[];
            dispatch(setLockboxes(result));
        }
    };

    const onSave = useCallback(
        debounce(
            async (
                options: {
                    steps: Itinerary[],
                    inputs: ItineraryInput[],
                    tripStartDate: string | null,
                    tripEndDate: string | null,
                }
            ) => {
                if (
                    options.tripStartDate &&
                    options.tripEndDate
                ) {
                    const manager = StepsDirectionsManager.getInstance();
                    const datesManager = new StepsDatesManager(
                        options.tripStartDate,
                        options.tripEndDate
                    );
                    if (
                        (
                            options.inputs.length !== options.steps.length ||
                            !options.inputs.every((item, index) => {
                                const step = options.steps[index];

                                if (step) {
                                    const equal = areStepsEqual(item, step);
                                    return equal;
                                }

                                return false;
                            })
                        ) &&
                        await manager.areTransportsAllValid(options.inputs) &&
                        datesManager.areDatesAllValid(options.inputs)
                    ) {
                        saveQueue.add(options.inputs);
                    }
                }
            },
            500
        ),
        []
    );

    const onChangeTripDates = useCallback(
        debounce(
            async (
                options: {
                    inputs: ItineraryInput[],
                    tripId: number | null,
                    tripStartDate: string | null,
                    tripEndDate: string | null
                }
            ) => {
                const lastStep = options.inputs[options.inputs.length - 1];
                if (
                    options.tripStartDate &&
                    options.tripEndDate
                ) {
                    const manager = StepsDirectionsManager.getInstance();
                    const datesManager = new StepsDatesManager(
                        options.tripStartDate,
                        options.tripEndDate
                    );
                    if (
                        lastStep &&
                        options.tripId &&
                        await manager.areTransportsAllValid(options.inputs) &&
                        datesManager.areDatesAllValid(options.inputs)
                    ) {
                        const startDate = window.moment.utc(options.tripStartDate).startOf('day').toISOString();
                        const endDate = window.moment.utc(
                            lastStep.step_type === 'END' ?
                                lastStep.start_date :
                                lastStep.end_date
                        ).endOf('day').toISOString();

                        if (window.moment.utc(endDate).isAfter(options.tripEndDate, 'day')) {
                            dispatch(setStatus('changing-trip-dates'));
                            const trip = await updateTripVersion(
                                options.tripId,
                                parseInt(GetCookie('trip_id_version') ?? '-1'),
                                { startDate, endDate }
                            );
                            if (trip) {
                                dispatch(SetDates({ startDate, endDate }));
                                enqueueSnackbar(
                                    t('itinerary.trip-period-extended-warning'),
                                    {
                                        variant: 'warning',
                                        anchorOrigin: {
                                            vertical: 'top',
                                            horizontal: 'center'
                                        },
                                        autoHideDuration: null,
                                        content(key, message) {
                                            return (
                                                <Alert
                                                    variant="filled"
                                                    severity="warning"
                                                    action={
                                                        <IconButton
                                                            style={{ color: 'white' }}
                                                            size="small"
                                                            onClick={() => closeSnackbar(key)}>
                                                            <Close />
                                                        </IconButton>
                                                    }
                                                    sx={(theme) => ({
                                                        "alignItems": 'center',
                                                        "backgroundColor": '#FF9800',
                                                        '.MuiAlert-action': {
                                                            padding: 0,
                                                            backgroundColor: '#FF9800'
                                                        },
                                                        'zIndex': theme.zIndex.appBar + 1
                                                    })}
                                                >
                                                    {message}
                                                </Alert>
                                            );
                                        }

                                    }
                                );
                            }
                        }
                    }
                }
            },
            500
        ),
        []
    );

    useEffect(() => {
        return () => {
            saveQueue.release();
        };
    }, []);

    useEffect(() => {
        fetchedItinerary.current = false;
        (async () => {
            const { pass_check, headers } = CheckBeforeRequest();
            if (pass_check) {
                try {
                    let usedVersion = GetCookie('trip_id_version');
                    usedVersion = version && version.toString() !== usedVersion ?
                        version.toString() :
                        usedVersion;
                    dispatch(setStatus('loading-itinerary'));
                    dispatch(setLoading(true));
                    const response = await axios.get<Itinerary[]>(
                        `${API_HREF}client/${window.id_owner}/trip/${tripId}/versions/${usedVersion}/itinerary/`,
                        { headers }
                    );
                    const result = response.data.map((item) => itineraryToItineraryInput(locale, item)).sort(sortItinerary);
                    dispatch(setStepsInputs(result));
                    dispatch(setLoading(false));
                    fetchedItinerary.current = true;

                    const steps = [...response.data].sort(sortItinerary);
                    saveQueue.setOnSuccess((steps) => dispatch(SetItinerary(steps)));
                    saveQueue.setInitialData(steps);
                    dispatch(SetItinerary(steps));
                } catch (error) {
                    console.error(error);
                } finally {
                    dispatch(setStatus('idle'));
                }
            }
        })();
    }, [tripId, version]);

    useEffect(() => {
        saveQueue.setOnUpdate(update);
    }, [update]);

    useEffect(() => {
        (async () => {
            const circuitIds = uniq(
                stepsInputs.map((step) => {
                    return step.circuit;
                })
            ).filter((item) => item).sort() as number[];
            const circuits = (
                await Promise.all(
                    circuitIds.map((id) => {
                        return getCircuit(id);
                    })
                )
            ).filter((item) => item) as Circuit[];
            setCircuits((state) => {
                const oldIds = state.map((item) => item.id).sort();

                if (
                    oldIds.length !== circuitIds.length ||
                    !oldIds.every((id) => {
                        return circuitIds.findIndex((item) => item === id) >= 0;
                    })
                ) {
                    return circuits;
                }

                return state;
            });

            const tripIds = uniq(
                stepsInputs.map((step) => {
                    return step.iti_type;
                })
            ).filter((item) => item).sort() as number[];
            const trips = (
                await Promise.all(
                    tripIds.map((id) => {
                        return getTrip(id);
                    })
                )
            ).filter((item) => item) as Trip[];
            setTrips((state) => {
                const oldIds = state.map((item) => item.id).sort();

                if (
                    oldIds.length !== tripIds.length ||
                    !oldIds.every((id) => {
                        return tripIds.findIndex((item) => item === id) >= 0;
                    })
                ) {
                    return trips;
                }

                return state;
            });
        })();
    }, [stepsInputs]);

    //update cart if there is any change
    useEffect(() => {
        const start = steps.find((item) => item.step_type === 'START');
        const end = steps.find((item) => item.step_type === 'END');

        if (
            refreshCart &&
            tripStartDate &&
            start &&
            end &&
            lastSteps.current.length > 0 &&
            (
                steps.length !== lastSteps.current.length ||
                !steps.every((originItem, index) => {
                    const targetItem = lastSteps.current[index];

                    if (targetItem) {
                        return areStepsEqual(originItem, targetItem);
                    }

                    return false;
                })
            )
        ) {
            GetItineraryFlight(
                cloneDeep(steps),
                tripStartDate,
                start,
                end,
                dispatch
            );
        }

        return () => {
            lastSteps.current = steps;
        };
    }, [steps, tripStartDate, refreshCart]);

    // normalize texts
    useEffect(() => {
        if (
            shouldNormalizeTexts &&
            !steps.every((step, index) => {
                const lastStep = lastSteps.current[index];
                return step.id === lastStep?.id;
            })
        ) {
            const { token, cancel } = axios.CancelToken.source();
            (async () => {
                try {
                    dispatch(setIsImportingTexts(true));
                    if (trip?.is_by_day_view) {
                        // get trip texts
                        const { headers } = CheckBeforeRequest();
                        let next: string | null = `${API_HREF}client/${window.id_owner}/trip/${GetCookie('trip_id')}/versions/${GetCookie('trip_id_version')}/by-day/`;
                        let result: ItineraryByDay[] = []
                        do {
                            const response: AxiosResponse<{
                                next: string | null,
                                results: ItineraryByDay[]
                            }> = await axios.get(
                                next,
                                {
                                    headers,
                                    cancelToken: token
                                }
                            );
                            for (const item of response.data.results) {
                                result.push(item);
                            }
                            next = response.data.next;
                        } while (next);

                        // normalize them
                        const newContent = extractStepsForContentByDay(
                            parseInt(GetCookie('trip_id_version') ?? '0'),
                            steps,
                            result
                        );

                        // patch them
                        await makeContentUpdateRequest({
                            tripId: parseInt(GetCookie('trip_id') ?? '0'),
                            version: parseInt(GetCookie('trip_id_version') ?? '0'),
                            tripToken: null,
                            items: {
                                mode: 'by-day',
                                content: result
                            },
                            steps: newContent,
                            tripToken: null,
                            token
                        });
                        dispatch(setShouldNormalizeTexts(false));
                        dispatch(
                            setItineraryContent({
                                version: parseInt(GetCookie('trip_id_version') ?? '0'),
                                data: { state: 'loading' }
                            })
                        );
                    } else {
                        // get trip texts
                        const { headers } = CheckBeforeRequest();
                        let next: string | null = `${API_HREF}client/${window.id_owner}/trip/${GetCookie('trip_id')}/versions/${GetCookie('trip_id_version')}/by-step/`;
                        let result: ItineraryByStep[] = []
                        do {
                            const response: AxiosResponse<{
                                next: string | null,
                                results: ItineraryByStep[]
                            }> = await axios.get(
                                next,
                                {
                                    headers,
                                    cancelToken: token
                                }
                            );
                            for (const item of response.data.results) {
                                result.push(item);
                            }
                            next = response.data.next;
                        } while (next);

                        // normalize them
                        const newContent = extractStepsForContentByStep(
                            parseInt(GetCookie('trip_id_version') ?? '0'),
                            steps,
                            result
                        );

                        // patch them
                        await makeContentUpdateRequest({
                            tripId: parseInt(GetCookie('trip_id') ?? '0'),
                            version: parseInt(GetCookie('trip_id_version') ?? '0'),
                            tripToken: null,
                            items: {
                                mode: 'by-step',
                                content: result
                            },
                            steps: newContent,
                            tripToken: null,
                            token
                        });
                        dispatch(setShouldNormalizeTexts(false));
                        dispatch(
                            setItineraryContent({
                                version: parseInt(GetCookie('trip_id_version') ?? '0'),
                                data: { state: 'loading' }
                            })
                        );
                    }
                } catch (error) {
                    if (!axios.isCancel(error)) {
                        throw error;
                    }
                } finally {
                    dispatch(setIsImportingTexts(false));
                }
            })();
            return () => {
                cancel();
            };
        }
        lastSteps.current = steps;
    }, [steps, shouldNormalizeTexts, trip]);

    useEffect(() => {
        if (tripStartDate && tripEndDate) {
            dispatch(fixInputsAnomalies());
        }
    }, [stepsInputs]);

    //always set itinerary start date to trip start date
    useEffect(() => {
        if (tripStartDate) {
            dispatch(setItineraryStartDate({ startDate: tripStartDate }));
        }
    }, [tripStartDate, stepsInputs]);

    useEffect(() => {
        //add start and end steps if there is no step yet
        if (tripId && tripStartDate && tripEndDate && tripVersion) {
            if (tripVersion.has_trip_starting_point) {
                dispatch(
                    createInitialSteps({
                        tripId,
                        startDate: tripStartDate,
                        endDate: tripEndDate
                    })
                );
            } else {
                dispatch(removeInitialSteps());
            }
        }
    }, [
        tripId,
        stepsInputs,
        tripStartDate,
        tripEndDate,
        tripVersion?.has_trip_starting_point
    ]);

    //merge same steps
    useEffect(() => {
        const result = stepsInputs.reduce((prev, current, index) => {
            const lastItem = prev[prev.length - 1];

            if (
                current.step_type === 'STEP' &&
                lastItem?.step_type === 'STEP' &&
                (
                    (lastItem.destination && lastItem.destination.destination_id === current.destination?.destination_id) ||
                    (
                        lastItem.places_id.length > 5 &&
                        lastItem.places_id === current.places_id
                    )
                ) &&
                current.circuit === lastItem.circuit &&
                current.circuit_trip_version === lastItem.circuit_trip_version &&
                current.variant === lastItem.variant &&
                current.iti_type === lastItem.iti_type
            ) {
                return prev.slice(0, index - 1).concat([
                    {
                        ...lastItem,
                        end_date: current.end_date
                    }
                ]);
            }

            return prev.concat([current]);
        }, [] as ItineraryInput[]);
        if (stepsInputs.length !== result.length) {
            dispatch(setStepsInputs(result));
        }
    }, [stepsInputs]);

    //always empty last step transport
    useEffect(() => {
        dispatch(emptyLastStepTransport());
    }, [stepsInputs]);

    useEffect(() => {
        if (map) {
            const controller = new AbortController();

            onAutoRecompute({
                map,
                tripStartDate,
                tripEndDate,
                inputs: stepsInputs,
                tripId,
                isDragging: context.isDragging,
                signal: controller.signal
            });

            return () => {
                try {
                    controller.abort('Aborted autocalculation.');
                } catch (error: any) {
                    if (error !== 'Aborted autocalculation.') {
                        throw error;
                    }
                }
            };
        }
    }, [
        map,
        stepsInputs,
        tripStartDate,
        tripEndDate,
        context.isDragging
    ]);

    //save start step and end step if any for later use
    useEffect(() => {
        const start = stepsInputs.find((item) => item.step_type === 'START');
        const end = stepsInputs.find((item) => item.step_type === 'END');

        if (tripId && start && start.trip === tripId) {
            dispatch(setLastStartStep({ tripId, step: start }));
        }

        if (tripId && end && end.trip === tripId) {
            dispatch(setLastEndStep({ tripId, step: end }));
        }
    }, [tripId, stepsInputs]);

    useEffect(() => {
        if (mountedRef.current) {
            if (refreshCart) {
                enqueueSnackbar(
                    t("itinerary.cart_update_tooltip1"),
                    {
                        variant: "warning",
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'center'
                        },
                        preventDuplicate: true,
                        autoHideDuration: 5000,
                        action: (key) => (
                            <Button
                                style={{ color: 'white', position: 'absolute', top: '0', right: '0' }}
                                size="small"
                                onClick={() => closeSnackbar(key)}>
                                <Close />
                            </Button>
                        )
                    }
                );
            } else {
                enqueueSnackbar(
                    t("itinerary.cart_update_tooltip2"),
                    {
                        variant: "info",
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'center'
                        },
                        preventDuplicate: true,
                        autoHideDuration: 5000,
                        action: key => (
                            <Button
                                style={{ color: 'white' }}
                                size="small"
                                onClick={() => closeSnackbar(key)}>
                                <Close />
                            </Button>
                        )
                    }
                );
            }
        }
        mountedRef.current = true;
    }, [refreshCart]);

    useEffect(() => {
        if (container) {
            onUpdateLockBoxes(container.offsetHeight);
            const observer = new ResizeObserver((entries) => {
                const entry = entries[0]!;
                onUpdateLockBoxes(entry.contentRect.height);
            });
            observer.observe(container);
            return () => {
                observer.unobserve(container!);
            };
        }
    }, [container, circuits, trips, stepsInputs]);

    useEffect(() => {
        if (map && fetchedItinerary.current) {
            onSave({
                inputs: stepsInputs,
                steps,
                tripStartDate,
                tripEndDate
            });
        }
    }, [
        tripStartDate,
        tripEndDate,
        steps,
        stepsInputs,
        map
    ]);

    useEffect(() => {
        if (map && fetchedItinerary.current) {
            onChangeTripDates({
                inputs: stepsInputs,
                tripStartDate,
                tripEndDate,
                tripId
            });
        }
    }, [
        map,
        stepsInputs,
        tripStartDate,
        tripEndDate,
        tripId
    ]);

    useEffect(() => {
        const departureStep = stepsInputs[0];
        const returnStep = stepsInputs[stepsInputs.length - 1];
        if (departureStep && returnStep) {
            dispatch(
                SetDepartureDestination({
                    city: departureStep.city_name,
                    country: departureStep.country_name,
                    start_date: departureStep.start_date,
                    end_date: departureStep.end_date,
                    lat: null,
                    lng: null,
                    place_id: departureStep.places_id,
                    retrieve: false
                })
            );
            dispatch(
                SetReturnDestination({
                    city: returnStep.city_name,
                    country: returnStep.country_name,
                    start_date: returnStep.start_date,
                    end_date: returnStep.end_date,
                    lat: null,
                    lng: null,
                    place_id: returnStep.places_id,
                    retrieve: false
                })
            );
        }
    }, [stepsInputs]);

    useEffect(() => {
        dispatch(distributeBlockIds({ steps }));
    }, [steps]);

    return (
        <>
            <Grid
                ref={(list) => setContainer(list)}
                container
                sx={{
                    position: 'relative',
                    paddingTop: 1.5,
                    paddingBottom: 1.5
                }}
            >
                <Grid item xs={2} sx={{ paddingLeft: 4 }}>
                    <ItineraryInvertStepsButton />
                </Grid>
                <Grid item xs={8}>
                    <Typography
                        variant="h5"
                        component="div"
                        textTransform="uppercase"
                        sx={{
                            opacity: 0.54,
                            fontWeight: 700,
                            textAlign: 'center'
                        }}
                    >
                        {t('itinerary.trip-itinerary')}
                    </Typography>
                </Grid>
                <Grid item xs={2} sx={{ textAlign: 'right', paddingRight: 4 }}>
                    <Tooltip title={t('itinerary.cart_update_tooltip1')}>
                        <IconButton
                            sx={{
                                "width": 21,
                                "height": 21,
                                "padding": 0.2,
                                "border": '2px solid #757575',
                                "position": 'relative',
                                '&:before': {
                                    content: '""',
                                    width: 2,
                                    height: '100%',
                                    backgroundColor: '#757575',
                                    transform: 'rotate(45deg)',
                                    position: 'absolute',
                                    display: refreshCart ? 'none' : 'block'
                                }
                            }}
                            onClick={() => onToggleCartUpdate(!refreshCart)}
                        >
                            <ShoppingCartCheckoutOutlined sx={{ fontSize: 13 }} />
                        </IconButton>
                    </Tooltip>
                </Grid>
                <Grid item xs={2} sx={{ marginTop: 1.5, paddingLeft: 4 }}>
                    <Box
                        sx={{
                            display: 'inline-block',
                            marginRight: 'auto',
                            textAlign: 'center',
                            opacity: 0.54
                        }}
                    >
                        <Typography
                            variant="body2"
                            fontWeight={700}
                            fontSize={12}
                            textTransform="uppercase"
                        >
                            {t('itinerary.total-nights')}
                        </Typography>
                        <Typography
                            variant="body2"
                            fontWeight={700}
                            fontSize={12}
                            textTransform="uppercase"
                        >
                            {datesManager.countTripNights(stepsInputs)}
                        </Typography>
                    </Box>
                </Grid>
                <Grid item xs={8}>
                    <Stack direction="row" alignItems="center" justifyContent="center">
                        <Typography
                            fontSize={10}
                            fontWeight={700}
                            textTransform="uppercase"
                            component="div"
                            sx={{
                                opacity: 0.54,
                                minWidth: 90,
                                maxWidth: 90
                            }}
                        >
                            {t('itinerary.full-trip')}
                        </Typography>
                        <Switch
                            checked={!tripVersion?.has_trip_starting_point}
                            onChange={(_, checked) => onToggleStartingPoint(!checked)}
                        />
                        <Typography
                            fontSize={10}
                            fontWeight={700}
                            textTransform="uppercase"
                            component="div"
                            sx={{
                                opacity: 0.54,
                                minWidth: 90,
                                maxWidth: 90
                            }}
                        >
                            {t('itinerary.terrestrial-only')}
                        </Typography>
                    </Stack>
                </Grid>
                <Grid
                    item display="flex"
                    justifyContent="flex-end"
                    xs={2}
                    sx={{ marginTop: 1.5, paddingRight: 4 }}
                >
                    <Box
                        sx={{
                            display: 'inline-block',
                            marginLeft: 'auto',
                            textAlign: 'center',
                            opacity: 0.54
                        }}
                    >
                        <Typography
                            variant="body2"
                            fontWeight={700}
                            fontSize={12}
                            textTransform="uppercase"
                        >
                            {t('itinerary.remaining')}
                        </Typography>
                        <Typography
                            variant="body2"
                            fontWeight={700}
                            fontSize={12}
                            textTransform="uppercase"
                        >
                            {datesManager.countTripNights(stepsInputs) - datesManager.countTotalNights(stepsInputs)}
                        </Typography>
                    </Box>
                </Grid>
                <Grid
                    item
                    xs={12}
                    sx={{ marginTop: 1, marginBottom: 1, marginLeft: 4, marginRight: 4 }}
                >
                    <Divider />
                </Grid>
                <Grid item xs={12} sx={{ position: 'relative' }}>
                    <List ref={listRef}>
                        <ItineraryStepItemDragLayer parentRef={listRef} />
                        {
                            circuitGrouppedSteps.map((group, groupIndex) => {
                                return group.map((step, stepIndex) => {
                                    const circuit = circuits.find((item) => {
                                        return item.id === step.circuit;
                                    });
                                    const trip = trips.find((item) => {
                                        return item.id === step.iti_type;
                                    });
                                    const index = circuitGrouppedSteps.slice(0, groupIndex).reduce((prev, current) => {
                                        return prev + current.length;
                                    }, 0) + stepIndex;
                                    const nextStepGroup = stepIndex === group.length - 1 ?
                                        circuitGrouppedSteps[groupIndex + 1] :
                                        null;
                                    const nextStep = group[stepIndex + 1] ?? (circuitGrouppedSteps[groupIndex + 1] ?? [])[0];

                                    let isLocked = trip ?
                                        trip.lock_itinerary :
                                        circuit?.trip?.lock_itinerary ?? false;

                                    if (
                                        circuit &&
                                        circuit.source !== 'OFFLINE'
                                    ) {
                                        isLocked = true;
                                    }

                                    if (circuit?.source === 'GESTOUR' && isConstructionMode) {
                                        isLocked = false;
                                    }

                                    return (
                                        <React.Fragment key={step.id}>
                                            {
                                                groupIndex === 0 &&
                                                stepIndex === 0 &&
                                                isNumber(step.circuit) &&
                                                step.circuit_start_date &&
                                                !window.moment.utc(step.start_date).startOf('day').isSame(
                                                    window.moment.utc(tripStartDate).startOf('day'),
                                                    'days'
                                                ) &&
                                                <ListItem sx={{ width: '100%' }}>
                                                    <Alert severity="warning" sx={{ width: '100%' }}>
                                                        {
                                                            t(
                                                                'itinerary.bloc-fixed-date-trip-hole-warning',
                                                                {
                                                                    count: window.moment.utc(step.start_date).startOf('day').diff(
                                                                        window.moment.utc(tripStartDate).startOf('day'),
                                                                        'days'
                                                                    )
                                                                }
                                                            )
                                                        }
                                                    </Alert>
                                                </ListItem>
                                            }
                                            {
                                                step.step_type === 'START' &&
                                                <ItineraryStepItem
                                                    index={index}
                                                    id={step.id}
                                                    type="START"
                                                    title={t('itinerary.departure')}
                                                    departure={step.end_date}
                                                    destination={(() => {
                                                        const name = [step.city_name, step.country_name].filter((item) => item).join(', ');
                                                        return name.length > 0 ?
                                                            name :
                                                            null;
                                                    })()}
                                                    transport={step.r2r_json?.vehicle?.kind ?? null}
                                                />
                                            }
                                            {
                                                step.step_type === 'STEP' &&
                                                <ItineraryStepItem
                                                    index={index}
                                                    id={step.id}
                                                    type="STEP"
                                                    title={
                                                        tripVersion?.has_trip_starting_point ?
                                                            t('itinerary.step-no', { no: index }) :
                                                            t('itinerary.step-no', { no: index + 1 })
                                                    }
                                                    destinationId={step.destination?.destination_id ?? 0}
                                                    destination={
                                                        step.destination ?
                                                            getLightDestinationName(locale, step.destination) :
                                                            `${step.city_name}, ${step.country_name}`
                                                    }
                                                    arrival={step.start_date}
                                                    departure={step.end_date}
                                                    picture={step.destination?.cover_pic__url ?? ''}
                                                    locked={isLocked}
                                                    group={groupIndex}
                                                    isInGroup={
                                                        group.length > 1 &&
                                                        (
                                                            !!step.circuit ||
                                                            !!step.iti_type
                                                        )
                                                    }
                                                    groupName={
                                                        trip?.name ?? circuit?.localization?.find((item) => {
                                                            return item.locale === locale;
                                                        })?.name ?? circuit?.name ?? null
                                                    }
                                                    first={stepIndex === 0}
                                                    last={stepIndex === group.length - 1}
                                                    prependInsertStepField={index === 0}
                                                    daysCount={datesManager.countStepNights(step)}
                                                    transport={step.r2r_json?.vehicle?.kind ?? null}
                                                    editableArrivalHourly={
                                                        !tripVersion?.has_trip_starting_point &&
                                                        groupIndex === 0 &&
                                                        stepIndex === 0
                                                    }
                                                />
                                            }
                                            {
                                                step.step_type === 'END' &&
                                                <ItineraryStepItem
                                                    index={index}
                                                    id={step.id}
                                                    type="END"
                                                    title={t('itinerary.arrival')}
                                                    arrival={step.start_date}
                                                    destination={(() => {
                                                        const name = [step.city_name, step.country_name].filter((item) => item).join(', ');
                                                        return name.length > 0 ?
                                                            name :
                                                            null;
                                                    })()}
                                                />
                                            }
                                            {
                                                step.step_type !== 'END' &&
                                                <ItineraryTransportItem
                                                    index={index}
                                                    stepId={step.id}
                                                    step={step}
                                                    nextStep={nextStep}
                                                    mode={step.r2r_json?.vehicle?.kind ?? 'unknown'}
                                                    extraMode={
                                                        step.r2r_json?.segments?.some((item) => {
                                                            return step.r2r_json?.vehicles?.[item.vehicle]?.kind === 'ferry';
                                                        }) ?
                                                            ['ferry'] :
                                                            []
                                                    }
                                                    selected={step.r2r_json?.selected ?? false}
                                                    distance={step.distance_transport_km}
                                                    time={step.r2r_json?.duration_string ?? ''}
                                                    last={
                                                        index === findLastIndex(stepsInputs, (step) => ['START', 'STEP'].includes(step.step_type))
                                                    }
                                                    hideTransport={index === stepsInputs.length - 1 && !tripVersion?.has_trip_starting_point}
                                                    hasError={
                                                        step.r2r_json?.vehicle?.kind === 'plane' &&
                                                        step.r2r_json?.operatingDays &&
                                                        !step.r2r_json?.operatingDays.includes(
                                                            parseInt(window.moment.utc(step.end_date).format('d'))
                                                        )
                                                    }
                                                    isBeingReset={
                                                        resetTransportsFor.findIndex(([aId, bId]) => {
                                                            return aId === step.id && bId === stepsInputs[index + 1]?.id;
                                                        }) >= 0
                                                    }
                                                    isAboveGroup={(nextStepGroup?.length ?? 0) > 1}
                                                    loadingDestination={loadingDestination}
                                                    operatingDays={step.r2r_json?.operatingDays}
                                                    onChangeTransport={() => onChangeTransport(step, stepsInputs[index + 1]!)}
                                                    onAddDestination={(destination) => onAddStep(index, destination)}
                                                    onResetTransport={() => stepsInputs[index + 1] && onResetTransport(step, stepsInputs[index + 1]!)}
                                                    disabled={
                                                        (
                                                            trip ?
                                                                trip.lock_itinerary :
                                                                circuit?.trip?.lock_itinerary ?? false
                                                        ) &&
                                                        stepIndex !== group.length - 1
                                                    }
                                                />
                                            }
                                            {
                                                nextStep?.step_type === 'STEP' &&
                                                isNumber(nextStep.circuit) &&
                                                (
                                                    step.circuit !== nextStep.circuit ||
                                                    step.circuit_trip_version !== nextStep.circuit_trip_version
                                                ) &&
                                                nextStep.circuit_start_date &&
                                                !datesManager.areDatesValidBetween(step, nextStep, true) &&
                                                window.moment.utc(step.end_date).add(
                                                    step.r2r_json?.duration ?? 0,
                                                    'minutes'
                                                ).isAfter(
                                                    window.moment.utc(nextStep.start_date)
                                                ) &&
                                                <ListItem sx={{ width: '100%', marginBottom: 3 }}>
                                                    <Alert severity="warning" sx={{ width: '100%' }}>
                                                        {t('itinerary.bloc-fixed-date-warning')}
                                                    </Alert>
                                                </ListItem>
                                            }
                                            {
                                                nextStep?.step_type === 'STEP' &&
                                                isNumber(nextStep.circuit) &&
                                                (
                                                    step.circuit !== nextStep.circuit ||
                                                    step.circuit_trip_version !== nextStep.circuit_trip_version
                                                ) &&
                                                nextStep.circuit_start_date &&
                                                !datesManager.areDatesValidBetween(step, nextStep, true) &&
                                                window.moment.utc(nextStep.start_date).diff(
                                                    window.moment.utc(step.end_date).add(
                                                        step.r2r_json?.duration ?? 0,
                                                        'minutes'
                                                    ),
                                                    'minutes'
                                                ) > 5 &&
                                                <ListItem sx={{ width: '100%', marginBottom: 3 }}>
                                                    <Alert severity="warning" sx={{ width: '100%' }}>
                                                        {
                                                            nextStep &&
                                                            t(
                                                                'itinerary.bloc-fixed-date-hole-warning',
                                                                {
                                                                    count: window.moment.utc(nextStep.start_date).startOf('day').diff(
                                                                        window.moment.utc(step.end_date).add(
                                                                            step.r2r_json?.duration ?? 0,
                                                                            'minutes'
                                                                        ).startOf('day'),
                                                                        'days'
                                                                    ),
                                                                    date: window.moment.utc(step.end_date).add(
                                                                        step.r2r_json?.duration ?? 0,
                                                                        'minutes'
                                                                    ).format('DD/MM/YYYY | HH:mm')
                                                                }
                                                            )
                                                        }
                                                        {
                                                            !nextStep &&
                                                            t(
                                                                'itinerary.bloc-fixed-date-hole-warning',
                                                                {
                                                                    count: window.moment.utc(step.start_date).startOf('day').diff(
                                                                        window.moment.utc(tripStartDate).startOf('day'),
                                                                        'days'
                                                                    ),
                                                                    date: window.moment.utc(step.end_date).add(
                                                                        step.r2r_json?.duration ?? 0,
                                                                        'minutes'
                                                                    ).format('DD/MM/YYYY | HH:mm')
                                                                }
                                                            )
                                                        }
                                                    </Alert>
                                                </ListItem>
                                            }
                                        </React.Fragment>
                                    );
                                });
                            })
                        }
                        {
                            stepsInputs.length === 0 &&
                            !context.isDragging &&
                            <ItineraryTransportItemAddInput
                                loading={loadingDestination || isCalculatingAtIndexZero}
                                sx={{ width: '85%', margin: 'auto', marginTop: 1.5 }}
                                onChoose={(destination) => onAddStep(0, destination)}
                            />
                        }
                        {
                            stepsInputs.length === 0 &&
                            context.isDragging &&
                            <ItineraryStepItemDropTarget index={0} forceShow />
                        }
                        {
                            !tripVersion?.has_trip_starting_point &&
                            stepsInputs.length > 0 &&
                            context.isDragging &&
                            <ItineraryStepItemDropTarget index={stepsInputs.length} />
                        }
                    </List>
                    {
                        lockBoxes.map((position) => {
                            const group = circuitGrouppedSteps[position.group];
                            const firstStep = (group ?? [])[0];

                            if (group && firstStep) {
                                const index = circuitGrouppedSteps.slice(0, position.group).reduce((prev, current) => {
                                    return prev + current.length;
                                }, 0);
                                return (
                                    <ItineraryLockBox
                                        key={position.group}
                                        {...position}
                                        firstStep={{
                                            type: "STEP",
                                            index,
                                            id: firstStep.id,
                                            title: tripVersion?.has_trip_starting_point ?
                                                t('itinerary.step-no', { no: index }) :
                                                t('itinerary.step-no', { no: index + 1 }),
                                            destinationId: firstStep.destination?.destination_id ?? 0,
                                            destination: position.type !== 'typical-trip' ?
                                                position.circuit.localization?.find((item) => {
                                                    return item.locale === locale;
                                                })?.name ?? position.circuit.name ?? '' :
                                                position.trip.name ?? '',
                                            arrival: firstStep.start_date,
                                            departure: firstStep.end_date,
                                            picture: firstStep.destination?.cover_pic__url ?? '',
                                            locked: position.type !== 'typical-trip' ?
                                                (
                                                    position.circuit.source !== 'GESTOUR' ||
                                                    !isConstructionMode
                                                ) &&
                                                (
                                                    !!position.circuit.trip?.lock_itinerary ||
                                                    position.circuit.source !== 'OFFLINE'
                                                ) :
                                                position.trip.lock_itinerary,
                                            group: position.group,
                                            isInGroup: true,
                                            groupName: position.type !== 'typical-trip' ?
                                                position.circuit.localization?.find((item) => {
                                                    return item.locale === locale;
                                                })?.name ?? position.circuit.name ?? null :
                                                position.trip.name,
                                            first: false,
                                            last: false,
                                            prependInsertStepField: false,
                                            daysCount: 0,
                                            transport: 'unknown'
                                        }}
                                        onUpdatedTrip={(trip) => {
                                            setCircuits((circuits) => {
                                                return circuits.map((circuit) => {
                                                    return {
                                                        ...circuit,
                                                        trip: circuit.trip?.id === trip.id ?
                                                            trip :
                                                            circuit.trip
                                                    };
                                                });
                                            });
                                            setTrips((trips) => {
                                                return trips.map((item) => {
                                                    if (item.id === trip.id) {
                                                        return trip;
                                                    }
                                                    return item;
                                                });
                                            });
                                            getCircuit.delete(
                                                circuits.find((item) => {
                                                    return item.trip?.id === trip.id;
                                                })?.id ?? 0
                                            );
                                            getTrip.delete(
                                                trips.find((item) => {
                                                    return item.id === trip.id;
                                                })?.id ?? 0
                                            );
                                        }}
                                    />
                                );
                            }
                        })
                    }
                </Grid>
            </Grid>
            {
                props.openTransportModification &&
                chooseTransportModalSteps &&
                <ItineraryTransportChooseModal
                    a={chooseTransportModalSteps[0]}
                    b={chooseTransportModalSteps[1]}
                    loading={loadingTransports}
                    transports={transports}
                    containerRef={containerRef}
                    onChooseTransport={onChangeStepTransport}
                    onClose={() => props.onToggleTransportModification(false)}
                />
            }
            {
                adalteModalAddOptions &&
                <Backdrop
                    open
                    onClick={(event) => event.stopPropagation()}
                    sx={(theme) => ({
                        zIndex: theme.zIndex.appBar + 1
                    })}
                >
                    <Box>
                        <HeaderPassenger
                            isOpen="passenger"
                            setIsOpen={(value) => {
                                if (!value) {
                                    dispatch(setAdalteModalAddOptions(null));
                                }
                            }}
                            circuitId={
                                adalteModalAddOptions.type === 'circuit' ?
                                    adalteModalAddOptions.circuitId ?? undefined :
                                    undefined
                            }
                            circuitVersion={
                                adalteModalAddOptions.type === 'circuit' ?
                                    adalteModalAddOptions.circuitVersion ?? undefined :
                                    undefined
                            }
                            startDate={adalteModalAddOptions.startDate}
                            loading={addingBlock}
                            onValidate={async (adaltePackages) => {
                                setAddingBlock(true);
                                await addBlock({
                                    ...adalteModalAddOptions,
                                    adalteRooms: adaltePackages[0]?.rooms ?? []
                                } as any);
                                setAddingBlock(false);
                            }}
                            forBlocks
                        />
                    </Box>
                </Backdrop>
            }
            {
                travelExchangeModalAddOptions &&
                <ItineraryBlocksTravelExchangeModal
                    loading={addingBlock}
                    onValidate={async (travelExchangeOptions) => {
                        setAddingBlock(true);
                        await addBlock({ ...travelExchangeModalAddOptions, travelExchangeOptions } as any);
                        setAddingBlock(false);
                    }}
                    onClose={() => {
                        dispatch(
                            setTravelExchangeModalAddOptions(null)
                        );
                    }}
                />
            }
        </>
    );
}
